@import "./bootstrap/colors";
@import "./utilities";
@import "./calendar";
@import "./select";
@import "./upload";
@import "./mat-dialog";

body {
  background-color: $lightblue;
}

.container {
  padding: 0 2rem;
}

.swiper-pagination-bullet-active {
  background-color: $primary !important;
}

.swiper-button-prev,
.swiper-button-next {
  color: #4f8ecc;
}
