$primary:       #005CB9;
$secondary:     #E50695;
$success:       #76BC21;
$warning:       #FFAA00;
$danger:        #E50695;
$light:         #d1d1d1;
$dark:          #617086;
$lightblue:     #F8FAFC;
$black:         #212529;
$grey-dark:     #404959;

$body-color:  #6F7F98;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
  "lightblue":  $lightblue,
  "black":      $black,
  "grey-dark":  $grey-dark
);