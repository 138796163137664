// $input-border-width: 0px;
// $input-focus-box-shadow: none;
// $input-color: $primary;
// $input-bg: transparent;
// $form-select-focus-box-shadow: none;
// $input-font-size:  1.125rem;
// $form-label-font-size: 0.875rem;

// .form-control:not([type=checkbox]), .form-select{
//   border-bottom: 2px solid $light !important;
//   border-radius: 0px !important;
// }
// .form-control:focus, .form-select:focus{
//   border-bottom: 2px solid $success !important;
// }
label{
 color: $dark;
}

label::after{
  background-color: transparent !important;
}

.form-control {
  background-color: transparent !important;
}