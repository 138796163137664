@import "./colors";

$nav-link-color: $black;
$nav-pills-link-active-bg: white;
$nav-pills-border-radius: 12px;
$nav-pills-link-active-color: $black;
$nav-link-padding-x: 3rem;
$nav-link-padding-y: 0.8rem;

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  box-shadow: 0px 15px 40px rgba(18, 27, 48, 0.06);
}
