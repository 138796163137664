@import "./colors";

$badge-font-size: 0.75em;
$badge-padding-y: 0.7em;
$badge-padding-x: 1.4em;

.badge {
  letter-spacing: 0.6px;

  &.badge-primary {
    background-color: lighten($primary, 55%);
    color: $primary;
  }
  &.badge-secondary {
    background-color: lighten($secondary, 50%);
    color: $secondary;
  }
  &.badge-success {
    background-color: lighten($success, 50%);
    color: $success;
  }
  &.badge-warning {
    background-color: lighten($warning, 40%);
    color: $warning;
  }
}
