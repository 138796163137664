/* You can add global styles to this file, and also import other style files */

$font-family-sans-serif: "Mabry Pro", "Helvetica Neue", sans-serif;

@import "video.js/dist/video-js.min.css";
@import "./assets/scss/bootstrap/colors";
@import "./assets/scss/bootstrap/buttons";
@import "./assets/scss/bootstrap/inputs";
@import "./assets/scss/bootstrap/card";
@import "./assets/scss/bootstrap/accordion";
@import "./assets/scss/bootstrap/tabs";
@import "./assets/scss/bootstrap/badge";
@import "./assets/scss/bootstrap/pagination";
@import "./assets/fonts/MabryPro/fonts.css";
@import "./assets/scss/styles.scss";
@import "bootstrap/scss/bootstrap";

@import "@ng-select/ng-select/themes/default.theme.css";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.modal-spinner {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}
.mdc-dialog__surface {
  overflow-y: visible !important;
}
.overlay-spinner {
  background-color: rgba(220, 220, 220, 0.612) !important;
}

.vjs-poster {
  background-position: center !important;
  background-size: cover !important;
}

.whatsapp-onboarding {
  position: fixed;
  bottom: 40px;
  text-decoration: none;
  right: 32px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 34px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.whatsapp-button {
  position: fixed;
  bottom: 0px;
  text-decoration: none;
  right: 20px;
  background-color: #25d366;
  color: #fff;
  border-radius: 20px 20px 0px 0px;
  width: 250px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 36px;
  z-index: 9999;
}

.whatsapp-button:hover {
  color: white;
  box-shadow: 0px 15px 15px 5px rgba(11, 218, 114, 0.6);
}

@media (max-width: 991px) {
  .whatsapp-button {
    width: 100%;
    right: 0;
  }
}

.table-error {
  overflow: auto;
  width: 100%;
  max-height: 50vh;
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
    overflow: auto;
  }

  table th,
  table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }

  table th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #f2f2f2;
  }

  table tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  table tr:hover {
    background-color: #f2f2f2;
  }
}

.p-paginator-asegurados {
  ol, ul {
    padding-left: 0 !important;
  }
}

