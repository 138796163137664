$pagination-border-radius:           .5rem;
$pagination-border-color:            transparent;
$pagination-disabled-bg:             transparent;
$pagination-bg:                      transparent;
$pagination-disabled-border-color:   transparent;
$pagination-hover-bg:                #FFF;
$pagination-hover-border-color:      transparent;

.page-item.active .page-link {
  border-radius: .5rem;
}

.page-link {
  margin-inline: .5rem;
  border-radius: .5rem;
}