.ng-select .ng-select-container {
  background-color: transparent;
}

.ng-select.ng-select-single .ng-select-container {
  height: 58px;
  padding-top: 1.5rem;
}

.p-calendar .p-inputtext {
  height: 58px;
  padding-top: 1.5rem;
}

.label-select {
  position: absolute;
  z-index: 1;
  padding: 0.5rem 0.75rem;
  font-size: 14px;
}

.form-select {
  background-color: transparent;
}

// .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
//   border-color: $success;
//   box-shadow: none;
// }

// .ng-select.ng-select-opened>.ng-select-container {
//   background-color: transparent;
// }

// .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
//   color: $primary;
// }