@import "./bootstrap/colors";

.p-datepicker-month,
.p-datepicker-year {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.1rem 0.5rem;
  line-height: 1.5;
  color: $primary;
  font-weight: bold;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.p-panel-small.p-datepicker table td {
  padding: 0;
  font-size: 14px;
}

.p-panel-small.p-datepicker table th {
  font-size: 14px;
  text-align: center;
}

.p-panel-small.p-datepicker table td > span {
  width: 2rem;
  height: 2rem;
}
.p-panel-small.p-datepicker table th > span {
  width: 2rem;
  height: 2rem;
}
.p-panel-small .p-datepicker-month,
.p-datepicker-year {
  border: none;
}
