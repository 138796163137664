@import "./bootstrap/colors";

.cursor-pointer {
  cursor: pointer;
}
.tracking-widest {
  letter-spacing: 0.2em;
}

.extended-navbar {
  width: 65%;
  background: white;
  // height: 300px;
  position: absolute;
  border-radius: 16px;
  transition: all 0.6s ease;
  bottom: 200px;
  z-index: 1;
  right: 40px;

  h2 {
    line-height: 1 !important;
  }

  .nav-item.active {
    color: $primary;

    .icon {
      color: $primary !important;
    }
  }
}

.mat-dialog-container {
  padding: 0px;
  overflow: initial !important;
}

.mat-primary {
  color: $primary !important;
}

.mat-accent {
  color: $secondary !important;
}

.mat-warn {
  color: $success !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.p-inputtext:enabled:focus {
  box-shadow: none !important;
}

.title__onboarding {
  z-index: 6;
}

.p-paginator {
  background-color: transparent;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: var(--bs-primary);
  color: #fff;
  border-radius: 8px;
}

.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  border-radius: 8px;
}

.opacity-50 {
  opacity: 0.5;
}

.rounded-radius {
  border-radius: 50px;
}

.p-calendar-w-btn .p-inputtext {
  border-radius: 3px !important;
}

.p-inputtext:enabled:focus {
  border-color: $primary;
}

.p-button:enabled:active {
  background: $primary !important;
  border-color: $primary !important;
}

.p-calendar-w-btn .p-datepicker-trigger {
  background: #eef0f2;
  border: none;
  position: absolute;
  color: #90a4ae;
  height: 100%;
  right: 0;
  border-radius: 3px;
}

.p-inputnumber-input {
  border: none;
  text-align: center;
}

.p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button-up,
.p-button.p-inputnumber-button-down {
  border-radius: 50% !important;
  background: #eef0f2;
  color: #90a4ae;
  border: none;
}

// .p-button:enabled:hover {
//   background-color: $primary;
//   color: #fff;
// }

.p-inputnumber-input {
  width: 100% !important;
}
